import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getRoleRequest, clearUser } from '../../core/redux/actions/authActions';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '../layout/Alert';
import { deactivateAlert } from '../../core/redux/actions/utilsActions';

import { clearAdmin } from '../../core/redux/actions/adminActions';
import { clearClient } from '../../core/redux/actions/clientActions';
import { clearAssociated } from '../../core/redux/actions/associatedActions';

const Admin = lazy(() => import('./Admin'));
// const Client = lazy(() => import('./Client'));
const Associated = lazy(() => import('./Associated'));
const Profile = lazy(() => import('./Home/Profile'));
const Login = lazy(() => import('./Auth/Login'));
const Register = lazy(() => import('./Auth/Register'));
const RecoverPassword = lazy(() => import('./Auth/RecoverPassword'));

const Start = props => {
	const { user, getRole, isLoadingRole, deactivateAlert, open, message, type, role } = props;

	const history = useHistory();

	useEffect(() => {
		if (user) {
			getRole();
		}
	}, [user, getRole]);

	const logOut = () => {
		props.logOutRequest();
		localStorage.removeItem('snap');
		localStorage.removeItem('optionSelected');
		history.push('/login');
	};

	useEffect(() => {
		if (role === 0) {
			logOut();
		}
	}, [role, logOut]);

	if ((isLoadingRole === undefined || isLoadingRole) && user) {
		return <LinearProgress />;
	} else {
		return (
			<Suspense fallback={<LinearProgress />}>
				<Alert open={open} setOpen={e => deactivateAlert()} message={message} type={type} />

				<Route exact path='/'>
					{typeof props.role !== 'number' && <Redirect to='/login' />}
				</Route>

				<Route exact path='/login'>
					{typeof props.role === 'number' && props.role !== 0 && <Redirect to='/' />}
				</Route>
				<Route exact path='/registro'>
					{typeof props.role === 'number' && <Redirect to='/' />}
				</Route>

				<Switch>
					<Route exact path='/password-reset' component={RecoverPassword} />

					{Number.isInteger(props.role) && <Route exact path='/perfil' component={Profile} />}

					{/* Cliente */}
					{/* props.role === 0 && <Route path='/' component={Client} /> */}

					{/* administrador */}
					{props.role === 1 && <Route path='/' component={Admin} />}

					{/* Asociado */}
					{props.role === 2 && <Route path='/' component={Associated} />}

					<Route exact path='/login' component={Login} />
					<Route exact path='/registro' component={Register} />
					<Route path='*' component={NoMatch} />
				</Switch>
			</Suspense>
		);
	}
};

function NoMatch() {
	const location = useLocation();
	// const pdfUrl = useMemo(() => ({ url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf' }));

	return (
		<div>
			<h3>
				404 No match for <code>{location.pathname}</code>
			</h3>

			{/* <PDFViewer
				document={{
					url: pdfUrl,
				}}
				showThumbnail={{ scale: 3 }}
			/> */}
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		getRole: () => {
			dispatch(getRoleRequest());
		},
		deactivateAlert: () => dispatch(deactivateAlert()),
		logOutRequest: () => {
			dispatch(clearUser());
			dispatch(clearAdmin());
			dispatch(clearClient());
			dispatch(clearAssociated());
		},
	};
};

const mapStateToProps = state => {
	return {
		user: state.authReducer.token,
		isLoadingRole: state.authReducer.isLoadingRole,
		role: state.authReducer.role,

		open: state.utilsReducer.open,
		message: state.utilsReducer.message,
		type: state.utilsReducer.type,
	};
};

Start.propTypes = {
	user: PropTypes.string,
	getRole: PropTypes.func.isRequired,
	isLoadingRole: PropTypes.bool,
	deactivateAlert: PropTypes.func.isRequired,
	open: PropTypes.bool,
	message: PropTypes.string,
	type: PropTypes.string,
	role: PropTypes.number,
	logOutRequest: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);
