import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItemIcon, ListItemText, ListItem, Divider, Toolbar, Collapse, ListItemButton } from '@mui/material';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuItems from '../../../menu-items';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Options = props => {
	const { open, onClose, openSettings, option, subOpen, subOnClose, optionSideBar, subOptionSideBar } = props;
	const history = useHistory();
	const { t } = useTranslation();
	const options = MenuItems.items[props.role] && MenuItems.items[props.role].children;
	const [valueOption, setValueOption] = useState(
		localStorage.getItem('optionSelected') ? localStorage.getItem('optionSelected') : '0'
	);
	const [firstOptionSub, setFirstOptionSub] = useState(0);

	// console.log("OPTION *****");
	useEffect(() => {
		// Se pinta normalmente ejecuta este effect antes del index correspondiente al modulo ejecutando administrador, asociado y cliente
		if ((subOptionSideBar && subOptionSideBar > 0) || subOptionSideBar === 0) {
			// console.log('SUBOPTION', typeof optionSideBar, optionSideBar);
			localStorage.setItem('optionSelected', `${optionSideBar}-${subOptionSideBar}`);
			// console.log("GUARDA", `${optionSideBar}-${subOptionSideBar}`);
			setValueOption(`${optionSideBar}-${subOptionSideBar}`);
		} else if (optionSideBar && optionSideBar >= 0) {
			// console.log('OPTION', typeof optionSideBar, optionSideBar, localStorage.getItem("optionSelected"));
			localStorage.setItem('optionSelected', `${optionSideBar}`);
			// console.log("GUARDA", `${optionSideBar}`);
			setValueOption(`${optionSideBar}`);
		} else {
			// console.log("ELSE");
			localStorage.setItem('optionSelected', `${0}`);
			// console.log("GUARDA", `${0}`);
			setValueOption(`${0}`);
		}
	}, [optionSideBar, subOptionSideBar]);

	const handleClick = (url, index, subIndex) => {
		if ((option === '2' && open) || option === '1') {
			onClose();
			subOnClose(false);
		}
		history.push(url);
	};

	return (
		<>
			<Toolbar />
			<Divider />
			<List>
				{options &&
					options.map((item, index) => {
						return item.subItems ? (
							<div key={index}>
								<ListItemButton
									onClick={() => {
										open ? subOnClose(!subOpen) : openSettings(!open);
										setFirstOptionSub(index);
									}}
									disabled
									sx={{ backgroundColor: 'transparent' }}
								>
									<ListItemIcon sx={{ minWidth: '35px' }}>
										{<item.icon color={valueOption.split('-')[0] === `${index}` ? 'primary' : ''} />}
									</ListItemIcon>
									<ListItemText
										primary={t(item.title)}
										primaryTypographyProps={{
											color: valueOption.split('-')[0] === `${index}` ? 'primary' : '',
											fontWeight: 'bold',
											variant: 'caption',
										}}
									/>
									{/* {subOpen && firstOptionSub === index ? <ExpandLess /> : <ExpandMore />} */}
								</ListItemButton>
								{item.items.map((subItem, subIndex) => (
									<Collapse
										in={true || (subOpen && firstOptionSub === index)}
										key={subIndex + 'subItem'}
										timeout='auto'
										unmountOnExit
									>
										<List component='div' disablePadding>
											<ListItemButton
												sx={{ pl: 4 }}
												onClick={() => handleClick(item.url + subItem.url, String(index), String(subIndex))}
												selected={valueOption === `${index}-${subIndex}`}
											>
												<ListItemIcon sx={{ minWidth: '35px' }}>
													{<subItem.icon color={valueOption === `${index}-${subIndex}` ? 'primary' : ''} />}
												</ListItemIcon>
												<ListItemText
													primary={t(subItem.title)}
													primaryTypographyProps={{
														color: valueOption === `${index}-${subIndex}` ? 'primary' : '',
														fontWeight: 'medium',
														variant: 'caption',
													}}
												/>
											</ListItemButton>
										</List>
									</Collapse>
								))}
							</div>
						) : (
							<ListItem button key={index} onClick={() => handleClick(item.url, String(index))}>
								<ListItemIcon sx={{ minWidth: '35px' }}>
									{<item.icon color={valueOption === `${index}` ? 'primary' : ''} />}
								</ListItemIcon>
								<ListItemText
									primary={t(item.title)}
									primaryTypographyProps={{
										color: valueOption === `${index}` ? 'primary' : '',
										fontWeight: 'medium',
										variant: 'caption',
									}}
								/>
							</ListItem>
						);
					})}
			</List>
			<Divider />
		</>
	);
};

const mapStateToProps = state => {
	return {
		role: state.authReducer.role,
		optionSideBar: state.authReducer.optionSideBar,
		subOptionSideBar: state.authReducer.subOptionSideBar,
	};
};

Options.propTypes = {
	role: PropTypes.number,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	openSettings: PropTypes.func,
	option: PropTypes.string,
	subOpen: PropTypes.bool,
	subOnClose: PropTypes.func,
	optionSideBar: PropTypes.number,
	subOptionSideBar: PropTypes.number,
};

export default connect(mapStateToProps)(Options);
