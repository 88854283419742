import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HistoryIcon from '@mui/icons-material/History';
import PublicIcon from '@mui/icons-material/Public';
import WorkIcon from '@mui/icons-material/Work';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

const admin = {
	id: 'admin-items',
	title: 'Admin',
	type: 'admin',
	children: [
		{
			id: 'desktop-item',
			title: 'desktop',
			type: 'item',
			url: '/',
			icon: DashboardIcon,
			subItems: false,
		},
		{
			id: 'administration-item',
			title: 'administration',
			type: 'item',
			url: '/administration',
			icon: PublicIcon,
			subItems: true,
			items: [
				{
					id: 'users-item',
					title: 'users',
					type: 'item',
					url: '/usuarios',
					icon: PeopleAltIcon,
					subItems: false,
				},
				{
					id: 'assignment-item',
					title: 'assignment',
					type: 'item',
					url: '/asignacion',
					icon: AssignmentIcon,
					subItems: false,
				},
				{
					id: 'parameters-item',
					title: 'parameters',
					type: 'item',
					url: '/parametros',
					icon: ListAltIcon,
					subItems: false,
				},
				{
					id: 'lumpsHistory-item',
					title: 'lumpsHistory',
					type: 'item',
					url: '/historial',
					icon: HistoryIcon,
					subItems: false,
				},
			],
			// icon: icons.IconHelp,
			// external: true,
			// target: true
		},
		{
			id: 'operations-item',
			title: 'operations',
			type: 'item',
			url: '/operations',
			icon: WorkIcon,
			subItems: true,
			items: [
				{
					id: 'lumps-item',
					title: 'consolidationOfPackages',
					type: 'item',
					url: '/consolidaciondepedidos',
					icon: LineWeightIcon,
					breadcrumbs: false,
					subItems: false,
				},
				{
					id: 'arrival-item',
					title: 'detailByConsolidation',
					type: 'item',
					url: '/detallesporconsolidacion',
					icon: FlightLandIcon,
					breadcrumbs: false,
					subItems: false,
				},
				{
					id: 'dropshipping-item',
					title: 'dropshipping',
					type: 'item',
					url: '/dropshipping',
					icon: PointOfSaleIcon,
					breadcrumbs: false,
					subItems: false,
				},
				{
					id: 'bodega-item',
					title: 'warehouse',
					type: 'item',
					url: '/bodega',
					icon: LocalMallIcon,
					breadcrumbs: false,
					subItems: false,
				},
				{
					id: 'orders-item',
					title: 'detailByCustomer',
					type: 'item',
					url: '/detalleporcliente',
					icon: ContactMailIcon,
					breadcrumbs: false,
					subItems: false,
				},
			],
			// icon: icons.IconHelp,
			// external: true,
			// target: true
		},
		{
			id: 'purchases-item',
			title: 'purchases',
			type: 'item',
			url: '/purchases',
			icon: ShoppingCartIcon,
			subItems: true,
			items: [],
			// icon: icons.IconHelp,
			// external: true,
			// target: true
		},
		{
			id: 'sales-item',
			title: 'sales',
			type: 'item',
			url: '/sales',
			icon: TrendingDownIcon,
			subItems: true,
			items: [],
			// icon: icons.IconHelp,
			// external: true,
			// target: true
		},
		{
			id: 'finances-item',
			title: 'finances',
			type: 'item',
			url: '/finances',
			icon: ShowChartIcon,
			subItems: true,
			items: [],
			// icon: icons.IconHelp,
			// external: true,
			// target: true
		},
	],
};

export default admin;
